var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: { classes: "new-email", visible: _vm.isModalVisible },
      on: {
        closeModal: function ($event) {
          return _vm.$emit("closeModal")
        },
      },
    },
    [
      _c("template", { slot: "header" }, [
        !_vm.action
          ? _c("div", { staticClass: "name" }, [_vm._v("NEUE EMAIL")])
          : _vm.action === "reply"
          ? _c("div", { staticClass: "name" }, [_vm._v("Antworten")])
          : _vm.action === "replyAll"
          ? _c("div", { staticClass: "name" }, [_vm._v("Allen Antworten")])
          : _vm.action === "forward"
          ? _c("div", { staticClass: "name" }, [_vm._v("Weiterleiten")])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "toggle-switch",
              {
                model: {
                  value: _vm.emailType,
                  callback: function ($$v) {
                    _vm.emailType = $$v
                  },
                  expression: "emailType",
                },
              },
              [
                _c("option", { attrs: { value: "client" } }, [
                  _vm._v("Email an Kunde"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "provider" } }, [
                  _vm._v("Email an Leistungsträger"),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "content" },
        [
          _c("orders-select", {
            attrs: { params: { _groups: ["client_read"] } },
          }),
          _vm._v(" "),
          _c("email-form", {
            ref: "form",
            attrs: {
              "preselected-order": _vm.preselectedOrder,
              "preselected-request": _vm.preselectedRequest,
              "preselected-contact": _vm.isReply
                ? null
                : _vm.preselectedContact,
              "select-template": _vm.selectTemplate,
              "attach-document": _vm.attachDocument,
            },
            on: { closeModal: (refresh) => _vm.$emit("closeModal", refresh) },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }