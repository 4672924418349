<template>
    <modal
        class="is-form"
        v-bind:visible="isVisible"
        v-on:closeModal="isVisible = false"
    >
        <p slot="header">Dokument hochladen</p>

        <div slot="content">
            <table>
                <tr v-if="!multiple">
                    <th><label class="label">Dokumentenname</label></th>
                    <td>
                        <div class="control">
                            <input type="text" class="input" v-model="form.name"/>
                        </div>
                    </td>
                </tr>

              <!--  <tr>
                    <th><label class="label">Details</label></th>
                    <td>
                        <div class="control">
                            <input type="text" class="input" v-model="form.description"/>
                        </div>
                    </td>
                </tr>-->

                <!--<tr>
                    <th><label class="label">Quelle</label></th>
                    <td>
                        <div class="control">
                            <input type="text" class="input" v-model="form.source"/>
                        </div>
                    </td>
                </tr>-->

                <tr>
                    <th><label class="label">Kategorie</label></th>
                    <td>
                        <div class="control">
                            <multiselect
                                v-model="category"
                                v-bind:options="categories"
                                track-by="key"
                                label="value"
                            />
                        </div>
                    </td>
                </tr>

                <tr v-if="category && category.key === 'prices'">
                    <th><label class="label">Preise für</label></th>
                    <td>
                        <div class="control">
                            <multiselect
                                v-model="form.year"
                                v-bind:options="years"
                            />
                        </div>
                    </td>
                </tr>

                <tr>
                    <th><label class="label">Zuordnung</label></th>
                    <td>
                        <div class="control">
                            <div class="select">
                                <select v-model="form.assignment">
                                    <!-- <option value="global">global</option> -->
                                    <option value="client">reiseunbezogen</option>
                                    <option value="order">reisebezogen</option>
                                </select>
                            </div>
                        </div>
                    </td>
                </tr>

                <!-- <template v-if="form.assignment !== 'global'"> -->
                    <tr>
                        <td colspan="2"><hr></td>
                    </tr>
                    <tr v-if="form.assignment === 'order'">
                        <th>
                            <label for="" class="label">Reise</label>
                        </th>
                        <td>
                            <div class="control">
                                <multiselect
                                    v-model="form.links.order"
                                    v-bind:options="options.orders"
                                    v-bind:loading="isOrderLoading"

                                    track-by="id"
                                    v-bind:custom-label="customLabel"

                                    v-on:search-change     = "searchOrder"
                                    v-bind:internal-search = "false"
                                    v-bind:clear-on-select = "false"

                                    multiple
                                >
                                    <span slot="caret"></span>
                                </multiselect>
                            </div>
                        </td>
                    </tr>
                    <tr v-else>
                        <th>
                            <label class="label">Kunde</label>
                        </th>
                        <td>
                            <div class="control">
                                <multiselect
                                    v-model="form.links.client"
                                    v-bind:options="options.clients"
                                    v-bind:loading="isClientLoading"

                                    track-by="id"
                                    label="companyName"

                                    v-on:search-change     = "searchClient"
                                    v-bind:internal-search = "false"
                                    v-bind:clear-on-select = "false"

                                    multiple
                                >
                                    <span slot="caret"></span>
                                </multiselect>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <label for="" class="label">Reisekonzept</label>
                        </th>
                        <td>
                            <div class="control">
                                <multiselect
                                    v-model="form.links.orderConcept"
                                    v-bind:options="options.orderConcepts"
                                    v-bind:loading="isOrderConceptLoading"

                                    track-by="id"
                                    label="name"

                                    v-on:search-change     = "searchOrderConcept"
                                    v-bind:internal-search = "false"
                                    v-bind:clear-on-select = "false"

                                    multiple
                                >
                                    <span slot="caret"></span>
                                </multiselect>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <label for="" class="label">Tageskonzept</label>
                        </th>
                        <td>
                            <div class="control">
                                <multiselect
                                    v-model="form.links.dayConcept"
                                    v-bind:options="options.dayConcepts"
                                    v-bind:loading="isDayConceptLoading"

                                    track-by="id"
                                    label="name"

                                    v-on:search-change     = "searchDayConcept"
                                    v-bind:internal-search = "false"
                                    v-bind:clear-on-select = "false"

                                    multiple
                                >
                                    <span slot="caret"></span>
                                </multiselect>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <label for="" class="label">Routenstichpunkt</label>
                        </th>
                        <td>
                            <div class="control">
                                <multiselect
                                    v-model="form.links.predefinedRouteDetail"
                                    v-bind:options="options.predefinedRouteDetails"
                                    v-bind:loading="isPredefinedRouteDetailLoading"

                                    track-by="id"
                                    label="routeDescription"

                                    v-on:search-change     = "searchPredefinedRouteDetail"
                                    v-bind:internal-search = "false"
                                    v-bind:clear-on-select = "false"

                                    multiple
                                >
                                    <span slot="caret"></span>
                                </multiselect>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <label for="" class="label">Agentur</label>
                        </th>
                        <td>
                            <div class="control">
                                <multiselect
                                    v-model="form.links.agency"
                                    v-bind:options="options.agencies"
                                    v-bind:loading="isAgencyLoading"

                                    track-by="id"
                                    label="name"

                                    v-on:search-change     = "searchAgency"
                                    v-bind:internal-search = "false"
                                    v-bind:clear-on-select = "false"

                                    multiple
                                >
                                    <span slot="caret"></span>
                                </multiselect>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <label for="" class="label">Region</label>
                        </th>
                        <td>
                            <div class="control">
                                <area-select v-model="form.links.area" multiple />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <label for="" class="label">Leistungsträger</label>
                        </th>
                        <td>
                            <div class="control">
                                <multiselect
                                    v-model="form.links.provider"
                                    v-bind:options="options.providers"
                                    v-bind:loading="isProviderLoading"

                                    track-by="id"
                                    label="name"

                                    v-on:search-change     = "searchProvider"
                                    v-bind:internal-search = "false"
                                    v-bind:clear-on-select = "false"

                                    multiple
                                >
                                    <span slot="caret"></span>
                                </multiselect>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <label for="" class="label">Ort</label>
                        </th>
                        <td>
                            <div class="control">
                                <place-select v-model="form.links.place" multiple />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <label for="" class="label">Destination</label>
                        </th>
                        <td>
                            <div class="control">
                                <destination-select v-model="form.links.destination" multiple/>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <label for="" class="label">Unternehmen</label>
                        </th>
                        <td>
                            <div class="control">
                                <multiselect
                                    v-model="form.links.organisation"
                                    v-bind:options="options.organisations"
                                    v-bind:loading="isOrganisationLoading"

                                    track-by="id"
                                    label="name"

                                    v-on:search-change     = "searchOrganisation"
                                    v-bind:internal-search = "false"
                                    v-bind:clear-on-select = "false"

                                    multiple
                                >
                                    <span slot="caret"></span>
                                </multiselect>
                            </div>
                        </td>
                    </tr>
                <tr>
                    <th>
                        <label for="" class="label">Bank</label>
                    </th>
                    <td>
                        <div class="control">
                            <multiselect
                                v-model="form.links.bank"
                                v-bind:options="options.banks"
                                v-bind:loading="isBankLoading"

                                track-by="id"
                                label="name"

                                v-on:search-change     = "searchBank"
                                v-bind:internal-search = "false"
                                v-bind:clear-on-select = "false"

                                multiple
                            >
                                <span slot="caret"></span>
                            </multiselect>
                        </div>
                    </td>
                </tr>
                <!-- </template> -->
            </table>
        </div>


        <div slot="footer" class="level">
            <button
                class="button"
                v-on:click.prevent="cancelModal"
            >Abbrechen</button>

            <button
                class="button is-success"
                v-bind:class="{'is-loading' : isLoading}"
                v-on:click.prevent="save"
                v-if="has(form, 'id')"
            >Speichern</button>

            <template v-else>
                <input class="is-hidden" type="file" ref="fileUpload" v-on:change="save">
                <button
                    class="button is-success"
                    v-bind:class="{'is-loading' : isLoading}"
                    v-on:click="$refs.fileUpload.click()"
                >Dokument auswählen und speichern</button>
            </template>
        </div>
    </modal>
</template>



<script>
import isEmpty from 'lodash/isEmpty'
import pickBy  from 'lodash/pickBy'
import merge   from 'lodash/merge'
import forEach from 'lodash/forEach'
import isArray from 'lodash/isArray'
import has     from 'lodash/has'

import { Multiselect, AreaSelect, PlaceSelect, DestinationSelect }  from '@components/form';
import Modal                                                        from '@components/Modal';
import { notifySuccess, notifyError }                               from '@components/Notification';
import { addDocument, editDocument }                                from '@api';
import search                                                       from './search';
import {currentUser} from "@utilities/functions";
import moment from "moment";


const emptyForm = {
    name:        null,
    description: null,
    source:      null,
    type:        null,
    year:        null,
    assignment:  'client',
    links: {
        order:         [],
        orderConcept:  [],
        dayConcept:    [],
        predefinedRouteDetail: [],
        client:        [],
        agency:        [],
        area:          [],
        place:         [],
        destination:   [],
        provider:      [],
        invoice:       [],
        organisation:  [],
        bank:          []
    },
}


export default {
    mixins: [search],

    props: {
        optionsDocuments: {
            type: Object,
        }
    },

    data: function () {
        return {
            form: Object.assign({}, emptyForm),
            isVisible: false,
            isLoading: false
        }
    },


    computed: {
        years () {
            const endYear = parseInt(moment().format('YYYY')) + 2;
            const years = [];
            for(let i = 2023; i <= endYear; i++) {
                years.push(i);
            }
            return years;
        },

        formData: function () {
            // We use FormData because of the file updloaded
            let formData = new FormData();

            formData.append('name',         this.jsonData.name);
            formData.append('description',  this.jsonData.description);
            formData.append('source',       this.jsonData.source);
            formData.append('type',         this.jsonData.type);
            formData.append('global',       this.jsonData.global);

            if (this.form.assignment !== 'global') {
                this.documentLinks.forEach(link => {
                    formData.append('links[]', JSON.stringify(link));
                });
            }

            return formData;
        },

        jsonData: function () {
            let data = {
                ...!this.multiple && { name: this.form.name },
                description:    this.form.description,
                source:         this.form.source,
                type:           this.form.type,
                global:         this.form.assignment === 'global',
                year:           this.form.year,
                links:          []
            }

            if (this.form.assignment !== 'global' ) {
                this.documentLinks.forEach(link => {
                    data.links.push(link);
                })
            }

            return data;
        },

        multiple: function () {
            return (Array.isArray(this.form.id) && this.form.id.length > 1);
        },

        categories: function () {
            return this.optionsDocuments && this.optionsDocuments.types ? this.optionsDocuments.types : [];
        },

        category: {
            get: function () {
                return this.categories.find(category => category.key === this.form.type)
            },
            set: function (category) {
                this.form.type = !!category ? category.key : null
            }
        }
    },


    methods: {
        has,

        openModal: function (data) {
            if (!isEmpty(data)) {
                const formKeys = ['name', 'description', 'assignment', 'source', 'type', 'id', 'year'];

                this.form = merge({}, emptyForm, pickBy(data, (value, key) => {
                    return formKeys.indexOf(key) !== -1 && value
                }));

                if (data.global) {
                    this.form.assignment = 'global'

                } else if (isArray(data.links) && data.links.length > 0) {
                    if (data.links[0].order) {
                        this.form.assignment = 'order';
                    } else if (data.links[0].client) {
                        this.form.assignment = 'client';
                    }

                    forEach(this.form.links, (value, key) => {
                        this.form.links[key] = data.links
                            .filter(link => link[key])
                            .map(link => link[key])
                    });
                }

                if (Array.isArray(data.id) && data.id.length === 1) {
                    this.form.id = data.id[0]
                }
            }

            this.isVisible = true;
        },


        save: function (event) {
            this.isLoading = true;

            if (this.form.id) {
                this.editDocuments();
            } else {
                this.addDocument(event.target.files[0]);
            }
        },


        editDocument: function (id) {
            //this.jsonData.name=this.jsonData.name.replace(/[&\/\\#,+@$~%.'":*?<>{}]/g, '');
            editDocument({
                id,
                data: this.jsonData
            })
                .then(response => {
                    this.onSuccess(response, 'Dokument wurde erfolgreich bearbeitet!');
                })
                .catch(this.onError);
        },


        editDocuments: function () {
            if (this.multiple) {
                this.form.id.forEach(id => {
                    this.editDocument(id);
                })

            } else {
                this.editDocument(this.form.id);
            }
        },


        addDocument: function (file) {
            let formData = this.formData;
            formData.append('user_file_upload', file);

            addDocument(formData)
                .then(this.onSuccess)
                .catch(this.onError);
        },


        cancelModal: function () {
            this.$emit('close', false);
            this.isVisible = false;
        },


        onSuccess: function (response, successMessage = 'Das Dokument wurde erfolgreich gespeichert!') {
            this.isLoading = false;
            notifySuccess(successMessage);
            this.isVisible = false;
            this.$emit('close', response.data);
        },


        onError: function (e) {
            this.isLoading = false;
            notifyError('Das Dokument konnte nicht gespeichert werden! Server Error!');
            this.isVisible = false;
            this.$emit('close', false);
        }
    },

    components: {
        Modal,
        Multiselect,
        AreaSelect,
        PlaceSelect,
        DestinationSelect,
    }
}
</script>
