<template>
    <div class="simple-box">
        <div><b>REISE AUSWÄHLEN UM LEISTUNGSTRÄGER UND LEISTUNGEN ANZUZEIGEN</b></div>
        <div>
            <label>Vorgänge:</label>
            <multiselect
                class               = "is-order-select"
                v-model             = "ordersSelected"
                v-bind:options      = "orders"
                v-bind:custom-label = "orderLabel"
                v-bind:multiple     = "true"

                v-bind:options-limit    = "10"
                v-bind:loading          = "isLoadingOrders"
                v-bind:internal-search  = "false"

                track-by    = "id"
                placeholder = ""

                v-on:search-change = "orderSearch"
            >
                <template slot="option" slot-scope="props">
                    <span class="option__id" v-bind:title="'#' + props.option.id"># {{ props.option.id }}</span>

                    <span class="option__name" v-bind:title="props.option.client.companyName"><i class="material-icons">perm_identity</i> {{ props.option.client.companyName }}</span>

                    <span class="option__name" v-bind:title="props.option.groupName"><i class="material-icons">group</i> {{ props.option.groupName }}</span>

                    <span class="option__name" v-bind:title="getDestinations(props.option)"><i class="material-icons">flag</i> {{ getDestinations(props.option) }}</span>

                    <i class="material-icons">date_range</i> {{ props.option.startAt | dayMonth }} - {{ props.option.endAt | fullYear }}
                </template>
                <span slot="caret" v-if="orders.length === 0"></span>
            </multiselect>
        </div>

        <div v-if="emailType === 'provider'">
            <label>Leistungsträger:</label>
            <multiselect
                class               = "is-order-select"
                v-model             = "providerSelected"
                v-bind:options      = "providers"
                v-bind:custom-label = "providerLabel"
                track-by            = "feID"


                v-bind:loading       = "isLoadingProviders"
                v-bind:disabled      = "providers.length === 0"
                v-bind:placeholder   = "providers.length === 0 ? 'Es gibt keine Anbieter oder Agenturen für diese Anfrage' : ''"

            ><span slot="caret" v-if="providers.length === 0"></span></multiselect>
        </div>
    </div>
</template>



<script>


import Loading from '@components/Loading';
import { Multiselect } from '@components/form';
import { dayMonth, fullYear } from '@utilities/functions';
import throttle from "lodash/throttle";
import store from '@components/emails/store';




export default {
    store: store,

    props: {
        params: {},
    },


    computed: {
        providers: {
            get: function () {
                return this.$store.state.providers;
            },
            set: function (value) {
                this.$store.state.providers = value;
            }
        },

        providerSelected: {
            get: function() {
                return this.$store.state.providerSelected;
            },
            set: function (value) {
                this.$store.state.providerSelected = value;
                if(value) {
                    this.$store.dispatch('resetToContacts', {contactToSelect: null, selectContact: true});
                    this.$store.dispatch('resetTemplates');
                }

            }
        },

        orders: {
            get: function () {
                return this.$store.state.orders;
            },
            set: function (value) {
                this.$store.commit('updateOrders', value);
            }
        },

        ordersSelected: {
            get: function() {
                return this.$store.state.ordersSelected;
            },
            set: function (value) {
                this.$store.state.ordersSelected = value;
            }
        },

        emailType: function () {
            return this.$store.state.emailType;
        },

        isLoadingOrders: {
            get: function() {
                return this.$store.state.isLoadingOrders;
            },
            set: function(value) {
                this.$store.commit('updateOrderLoading', value);
            }
        },

        isLoadingProviders: {
            get: function() {
                return this.$store.state.isLoadingProviders;
            },
            set: function(value) {
                this.$store.state.isLoadingProviders = value;
            }
        },





    },


    methods: {
        orderSearch: throttle(function (query) {
            this.$store.dispatch('orderSearch', query);
        }, 900),

        orderLabel: function (order) {
            return '# ' + order.orderNumber +
                    ' - ' + order.client.companyName +
                    ' - ' + dayMonth(order.startAt) + ' - ' + fullYear(order.endAt);
        },


        providerLabel: function (provider) {
            return provider.provider.name;
        },

        getDestinations: function (order) {
            return order.trip.destinations.map(item => item.name).join(', ');
        }
    },


    watch: {
        ordersSelected: function () {
            // Reset everything
            this.providerSelected = null;
            this.providers = [];

            if (this.emailType === 'provider' && this.ordersSelected.length > 0) {
                this.$store.dispatch('getRequests');
            }
        },


        providerSelected: function () {
            this.$store.dispatch('getRequests');
        }
    },


    filters: {
        dayMonth,
        fullYear
    },


    components: {
        Loading,
        Multiselect
    }
}


</script>
