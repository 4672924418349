var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "simple-box" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("label", [_vm._v("Vorgänge:")]),
        _vm._v(" "),
        _c(
          "multiselect",
          {
            staticClass: "is-order-select",
            attrs: {
              options: _vm.orders,
              "custom-label": _vm.orderLabel,
              multiple: true,
              "options-limit": 10,
              loading: _vm.isLoadingOrders,
              "internal-search": false,
              "track-by": "id",
              placeholder: "",
            },
            on: { "search-change": _vm.orderSearch },
            scopedSlots: _vm._u([
              {
                key: "option",
                fn: function (props) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "option__id",
                        attrs: { title: "#" + props.option.id },
                      },
                      [_vm._v("# " + _vm._s(props.option.id))]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "option__name",
                        attrs: { title: props.option.client.companyName },
                      },
                      [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("perm_identity"),
                        ]),
                        _vm._v(" " + _vm._s(props.option.client.companyName)),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "option__name",
                        attrs: { title: props.option.groupName },
                      },
                      [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("group"),
                        ]),
                        _vm._v(" " + _vm._s(props.option.groupName)),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "option__name",
                        attrs: { title: _vm.getDestinations(props.option) },
                      },
                      [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("flag"),
                        ]),
                        _vm._v(" " + _vm._s(_vm.getDestinations(props.option))),
                      ]
                    ),
                    _vm._v(" "),
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("date_range"),
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("dayMonth")(props.option.startAt)) +
                        " - " +
                        _vm._s(_vm._f("fullYear")(props.option.endAt)) +
                        "\n            "
                    ),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.ordersSelected,
              callback: function ($$v) {
                _vm.ordersSelected = $$v
              },
              expression: "ordersSelected",
            },
          },
          [
            _vm._v(" "),
            _vm.orders.length === 0
              ? _c("span", { attrs: { slot: "caret" }, slot: "caret" })
              : _vm._e(),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm.emailType === "provider"
      ? _c(
          "div",
          [
            _c("label", [_vm._v("Leistungsträger:")]),
            _vm._v(" "),
            _c(
              "multiselect",
              {
                staticClass: "is-order-select",
                attrs: {
                  options: _vm.providers,
                  "custom-label": _vm.providerLabel,
                  "track-by": "feID",
                  loading: _vm.isLoadingProviders,
                  disabled: _vm.providers.length === 0,
                  placeholder:
                    _vm.providers.length === 0
                      ? "Es gibt keine Anbieter oder Agenturen für diese Anfrage"
                      : "",
                },
                model: {
                  value: _vm.providerSelected,
                  callback: function ($$v) {
                    _vm.providerSelected = $$v
                  },
                  expression: "providerSelected",
                },
              },
              [
                _vm.providers.length === 0
                  ? _c("span", { attrs: { slot: "caret" }, slot: "caret" })
                  : _vm._e(),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("b", [
        _vm._v("REISE AUSWÄHLEN UM LEISTUNGSTRÄGER UND LEISTUNGEN ANZUZEIGEN"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }